import React from "react";
import { TabData } from "../../component/Reusable/TabData";
import Tabs from "../../component/Tabs/Tabs";
import HelmetComponent from "../../component/Reusable/HelmetComponent";

const TermsAndConditions = () => {
  const [subTitle, setSubTitle] = React.useState("Terms and Conditions");
  return (
    <>
      <HelmetComponent
        title="Lydia Coin - Terms and Conditions"
        description="Review the terms and conditions for using Lydia Coin."
        keywords="Lydia Coin, Terms, Conditions, Policy"
        ogTitle="Lydia Coin - Terms and Conditions"
        ogDescription="Review the terms and conditions for using Lydia Coin."
        ogImage="https://lydia-coin-landing-page.vercel.app"
        ogUrl="https://lydia-coin-landing-page.vercel.app"
        twitterTitle="Lydia Coin - Terms and Conditions"
        twitterDescription="Review the terms and conditions for using Lydia Coin."
        twitterImage="https://lydia-coin-landing-page.vercel.app"
      />
      <div className="b-container-big py-28 flex flex-col gap-[120px]">
        <div className="flex flex-col flex-1  items-center gap-[1.875rem]">
          <div className="!text-[1.875rem] lydiaTitleContainer text-center md:!text-[54px]  b-6">
            Lydia Stable Coin Terms and Conditions
          </div>
          <div className="text-center text-subColor md:w-[65%] ">
            Lorem ipsum dolor sit amet consectetur. Cursus sed metus eu in diam
            ante sit quis consectetur. Amet integer nulla elementum leo
            ullamcorper elementum adipiscing mollis. Vitae bibendum ullamcorper.
            Eget malesuada quis dictumst sed scelerisque at.
          </div>
        </div>
        <Tabs tabs={TabData} setSubTitle={setSubTitle} />
      </div>
    </>
  );
};
export default TermsAndConditions;
