import React, { useEffect, useRef, useState } from "react";
import {
  TransparencyBank,
  TransparencyIcon1Light,
  TransparencyIcon2Light,
  TransparencyIcon3Light,
} from "../../constants/assets";
import JoinLydia from "../../constants/JoinLydia";
import HelmetComponent from "../../component/Reusable/HelmetComponent";
import { useTotalSupplyContext } from "../../context/totalSupplyContext";
import { formatBigInt } from "../../component/Reusable/reusable";

export const Transparency: React.FC = (): JSX.Element => {
  const { totalSupply, loading, error: supplyError } = useTotalSupplyContext();

  const [count, setCount] = useState<string>("0");

  const TotalSupply = BigInt(totalSupply ? totalSupply : 0);

  useEffect(() => {
    if (TotalSupply !== null && TotalSupply > BigInt(0)) {
      const duration = 2000; // Duration for the counting animation
      const steps =
        TotalSupply < BigInt(20) ? BigInt(1) : BigInt(duration / 100);
      const increment = TotalSupply / steps || BigInt(1); // Ensure increment is at least 1
      const interval = setInterval(() => {
        setCount((prevCount) => {
          const newCount = BigInt(prevCount) + increment;
          if (newCount < TotalSupply) {
            return newCount.toString(); // Convert back to string for state
          }
          clearInterval(interval); // Clear the interval once count reaches totalSupply
          return TotalSupply.toString(); // Set to totalSupply when done
        });
      }, 100); // Update every 100ms

      return () => clearInterval(interval); // Cleanup on unmount
    }
  }, [totalSupply]);

  return (
    <>
      <HelmetComponent
        title="Lydia Coin - Transparency"
        description="Discover Lydia Coin's commitment to transparency and openness."
        keywords="Lydia Coin, Transparency, Open, Trust"
        ogTitle="Lydia Coin - Transparency"
        ogDescription="Discover Lydia Coin's commitment to transparency and openness."
        ogImage="https://lydia-coin-landing-page.vercel.app"
        ogUrl="https://lydia-coin-landing-page.vercel.app"
        twitterTitle="Lydia Coin - Transparency"
        twitterDescription="Discover Lydia Coin's commitment to transparency and openness."
        twitterImage="https://lydia-coin-landing-page.vercel.app"
      />
      {/* New transparency page*/}

      <div className="font-inter flex-grow md:mb-8">
        <section className="lg:flex lg:flex-row pt-28" id="eursIntroduction">
          <div className="b-container-big lg:w-full lg:mx-auto lg:max-w-[1280px] w-full justify-between flex flex-col-reverse lg:items-center lg:flex-row md:gap-8">
            <div className="pb-10 bg-grayBackground lg:bg-transparent lg:px-0 px-4 flex flex-col  lg:pt-0 lg:max-w-[590px] md:max-lg:max-w-[458px] md:max-lg:mx-auto">
              <h1 className="lydiaTitleContainer lg:max-w-[500px] ">
                Our Commitment to Transparency
              </h1>

              <div className="pt-6 text-subColor text-opacity-60">
                <span className="">
                  At Lydia Coin, transparency is one of our core values. We
                  believe that trust is earned through openness and
                  accountability, which is why we are committed to providing
                  complete visibility into how Lydia Coin operates. From our
                  backing assets to our security practices, we ensure that our
                  users have access to all the information they need to trust
                  and engage with our platform.
                </span>
              </div>
            </div>
            <div className="flex flex-col items-center text-white text-[3.2rem] text-right circulations justify-center">
              <div className="md:text-[70px] text-[#003a60] text-[30px] text-center md:text-left break-all">
                {count ? formatBigInt(BigInt(count)) : "0"}
                 {" "}
                <span className="whitespace-nowrap">LYDC</span>
              </div>
              <div className="text-[1.5rem] text-[#003a60] md:pl-16 text-center">
                Lydia in circulation
              </div>
            </div>
          </div>
        </section>
        <section className="gridBackground py-20  md:justify-center md:flex">
          <div className="b-container !px-0 md:max-w-[485px] lg:max-w-[1280px]">
            <div className="b-container-column flex items-center justify-around lg:flex-row flex-col">
              <div className="xl:block lg:w-1/2 w-full mb-10 lg:mb-0 flex justify-center">
                <img
                  src={TransparencyBank}
                  alt="Lydia"
                  width={400}
                  height={400}
                  className="m-auto md:m-0"
                />
              </div>
              <div className=" lg:w-1/2 w-full">
                <h2 className="lydiaSubtitleContainer  font-semibold leading-10">
                  Fully Backed by Real Assets
                </h2>
                <div className="mt-4 text-subColor opacity-70 ">
                  Lydia Coin maintains its stable value by being fully backed by
                  USD ($) held in reserve. For every Lydia Coin in circulation,
                  there is an equivalent amount of fiat currency securely stored
                  in audited accounts. This ensures that Lydia Coin remains
                  stable and trustworthy, providing users with confidence in its
                  value.
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-white pt-5 pb-28 xl:py-24 lg:px-4 2xl:px-0 md:justify-center md:flex">
          <div className="b-container-big md:max-w-[485px] lg:max-w-[1280px]">
            <div className="b-container-column flex flex-col md:flex-row md:max-lg:flex-wrap max-md:space-y-[4rem] w-full lg:gap-[32px] justify-center md:max-lg:items-center">
              <div className="flex flex-grow md:max-lg:mb-[32px] lg:min-w-[32%] flex-col">
                <div className="py-10 px-8 rounded-[1.6rem] bg-[#FAFAFB] text-subColor-1 min-h-full">
                  <div className="min-h-[80%]">
                    <img src={TransparencyIcon1Light} alt="Lydia" />
                    <h3 className="text-[#003a60] text-[1.5rem] font-semibold mt-5">
                      Daily statement
                    </h3>
                    <div className="text-base">
                      We update our statement on daily basis. Current working
                      day for previous date
                    </div>
                  </div>
                  <div className="mt-6 flex items-center space-x-[1.2rem]">
                    <div className="flex px-[15px] py-[11px] bg-white rounded-[10px] cursor-pointer border border-[#eef0f3] hover:border-[#007DFC] hover:border-opacity-30">
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15"
                          stroke="#007DFC"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M7 10L12 15L17 10"
                          stroke="#007DFC"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12 15V3"
                          stroke="#007DFC"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <span className="text-base">August 28, 2024</span>
                  </div>
                </div>
                <button className="btnSecondaryWithBorder btnDefault max-h-[48px] flex flex-row items-center justify-center lg:mt-4 mt-2">
                  {/**/}
                  <span className="text-[16px] leading-button capitalize">
                    See older statements
                  </span>
                  {/**/}
                  {/**/}
                  {/**/}
                </button>
              </div>
              <div className="flex flex-grow md:max-lg:mb-[32px] lg:min-w-[32%] flex-col">
                <div className="py-10 px-8 rounded-[1.6rem] bg-[#FAFAFB] text-subColor-1 min-h-full">
                  <div className="min-h-[80%]">
                    <img src={TransparencyIcon2Light} alt="Lydia" />
                    <h3 className="text-[#003a60] text-[1.5rem]  font-semibold mt-5">
                      Quarterly verification
                    </h3>
                    <div className="text-base">
                      We update our statement on daily basis. Current working
                      day for previous date
                    </div>
                  </div>
                  <div className="mt-6 flex items-center space-x-[1.2rem]">
                    <div className="flex px-[15px] py-[11px] bg-white rounded-[10px] cursor-pointer border border-[#eef0f3] hover:border-[#007DFC] hover:border-opacity-30">
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15"
                          stroke="#007DFC"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M7 10L12 15L17 10"
                          stroke="#007DFC"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12 15V3"
                          stroke="#007DFC"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <span className="text-base">August 03, 2024</span>
                  </div>
                </div>
                <button className="btnSecondaryWithBorder btnDefault max-h-[48px] flex flex-row items-center justify-center lg:mt-4 mt-2">
                  {/**/}
                  <span className="text-[16px] leading-button capitalize">
                    See older verifications
                  </span>
                  {/**/}
                  {/**/}
                  {/**/}
                </button>
              </div>
              <div className="flex flex-grow md:max-lg:mb-[32px] lg:min-w-[32%] flex-col">
                <div className="py-10 px-8 rounded-[1.6rem] bg-[#FAFAFB] text-subColor-1 min-h-full">
                  <div className="min-h-[80%]">
                    <img src={TransparencyIcon3Light} alt="Lydia" />
                    <h3 className="text-[#003a60] text-[1.5rem] font-semibold mt-5">
                      Annual audit
                    </h3>
                    <div className="text-base">
                      We update our statement on daily basis. Current working
                      day for previous date
                    </div>
                  </div>
                  <div className="mt-6 flex items-center space-x-3">
                    <div className="flex px-[15px] py-[11px] bg-white rounded-[10px] cursor-pointer border border-[#eef0f3] hover:border-[#007DFC] hover:border-opacity-30">
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15"
                          stroke="#007DFC"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M7 10L12 15L17 10"
                          stroke="#007DFC"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12 15V3"
                          stroke="#007DFC"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <span className="text-base">May 20, 2024</span>
                  </div>
                </div>
                <button className="btnSecondaryWithBorder btnDefault max-h-[48px] flex flex-row items-center justify-center lg:mt-4 mt-2">
                  {/**/}
                  <span className="text-[16px] leading-button capitalize">
                    See older audits
                  </span>
                  {/**/}
                  {/**/}
                  {/**/}
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>

      <JoinLydia />
    </>
  );
};
