import { useEffect, useState } from "react";
import { ethers } from "ethers";

const useTotalSupply = () => {
  const [totalSupply, setTotalSupply] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  // Get values from environment variables
  const rpcUrl = process.env.REACT_APP_RPC_URL;
  const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
  const abi = [
    {
      inputs: [],
      name: "totalSupply",
      outputs: [
        {
          internalType: "uint256",
          name: "",
          type: "uint256",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
  ];

  useEffect(() => {
    const fetchTotalSupply = async () => {
      setLoading(true);
      setError(null);
      try {
        if (!rpcUrl || !contractAddress) {
          throw new Error("RPC URL or contract address is undefined");
        }
        const provider = new ethers.JsonRpcProvider(rpcUrl);
        const contract = new ethers.Contract(contractAddress, abi, provider);
        const supply = await contract.totalSupply();
        setTotalSupply(supply.toString());
      } catch (err) {
        setError("Error fetching total supply");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
    fetchTotalSupply();
  }, []);

  return { totalSupply, loading, error };
};

export default useTotalSupply;
