import FaqBox from "../../component/faqBox/FaqBox";
import HelmetComponent from "../../component/Reusable/HelmetComponent";

const LydiaData = {
  title: "About Lydia",
  question: [
    {
      question: "Is there a free trial available?",
      answer:
        "Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.",
    },
    {
      question: "Can I change my plan later?",
      answer:
        "Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.",
    },
    {
      question: "Can other info be added to an invoice?",
      answer:
        "Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.",
    },
    {
      question: "What is your cancellation policy?",
      answer:
        "Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.",
    },
    {
      question: "How does billing work?",
      answer:
        "Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.",
    },
    {
      question: "How do I change my account email?",
      answer:
        "Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.",
    },
  ],
};

const WalletData = {
  title: "About Wallet",
  question: [
    {
      question: "What is  Wallet?",
      answer:
        "Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.",
    },
    {
      question: "Wallet background",
      answer:
        "Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.",
    },
    {
      question: "What’s the origin of Wallet's name?",
      answer:
        "Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.",
    },
  ],
};
export const Faq: React.FC = (): JSX.Element => {
  return (
    <>
      <HelmetComponent
        title="Lydia Coin - FAQ"
        description="Learn about Lydia Coin, a secure and stable digital currency."
        keywords="Lydia Coin, Cryptocurrency, Stable Coin, Digital Assets"
        ogTitle="Lydia Coin FAQ"
        ogDescription="Find answers to common questions about Lydia Coin."
        ogImage="https://lydia-coin-landing-page.vercel.app"
        ogUrl="https://lydia-coin-landing-page.vercel.app"
        twitterTitle="Lydia Coin FAQ"
        twitterDescription="Find answers to common questions about Lydia Coin."
        twitterImage="https://lydia-coin-landing-page.vercel.app"
      />

      <div className="font-inter flex-grow">
        <section
          className="pt-28 bg-grayBackground pb-15 md:pb-20 mainBlock"
          id="faqHeader"
        >
          <div className="b-container-big">
            <div>
              {/* <h1 className="text-primary text-sm uppercase">faq</h1> */}
              <h2 className="lydiaTitleContainer">
                Frequently Asked Questions
              </h2>
            </div>
          </div>
        </section>
        <section className="bg-white"  >
          <div className="lg:px-4 lg:w-full lg:max-w-[1280px] lg:mx-auto pb-[80px]">
            <div className="md:mx-0 flex flex-col gap-10">
              <FaqBox data={LydiaData} />
              {/* <FaqBox id="aboutEurs" data={EursData} /> */}
              <FaqBox data={WalletData} />
            </div>
          </div>
        </section>
        {/*]*/}
      </div>
    </>
  );
};
