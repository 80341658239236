import React, { useState } from "react";

// Define types for props
interface Question {
  question: string;
  answer: string;
}

interface FAQData {
  logo?: JSX.Element;
  title?: string;
  question?: Question[];
}

interface FAQSectionProps {
  id?: string;
  data?: FAQData;
}

const FaqBox: React.FC<FAQSectionProps> = (props) => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const handleToggle = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <section className="pt-10" id={props.id}>
      <div className="border-b-[1px] border-b-[#003a601a] pb-8">
        <div className="flex items-center ml-4">
          {/* {props.data?.logo} */}

          <span className="text-[#003a60] font-semibold text-[2.25rem]">
            {props.data?.title}
          </span>
        </div>
      </div>
      {props.data?.question?.map((item, index) => (
        <div
          key={index}
          className="border-b-[1px] border-b-[#003a601a] text-subColor-1"
        >
          <div
            className="pb-5 ml-5 font-semibold text-lg flex flex-row items-center justify-between pt-5 cursor-pointer question"
            onClick={() => handleToggle(index)}
          >
            {item.question}
            <div>
              {openIndex === index ? (
                // Cross icon when the answer is visible
                <svg
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="ml-5 mr-5 md:mr-6 cursor-pointer text-[#96ADBD]"
                >
                  <path
                    d="M5 12H19"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

              ) : (
                // Plus icon when the answer is hidden
                <svg
                  width={24}
                  height={25}
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="ml-5 mr-5 md:mr-6 cursor-pointer text-[#96ADBD]"
                >
                  <path
                    d="M12 5.5V19.5"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5 12.5H19"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                  />
                </svg>
              )}
            </div>
          </div>
          <div
            className={`faq-answer ml-5 mr-5 md:mr-[7rem] pb-5 text-base ${
              openIndex === index ? "faq-open" : "faq-closed"
            }`}
          >
            {item.answer}
          </div>
        </div>
      ))}
    </section>
  );
};

export default FaqBox;
