import { useNavigate } from "react-router-dom";

const JoinLydia = () => {
  const navigate = useNavigate();
  return (
    <div className="b-container-big md:my-8 my-4">
      <div className="lydiaTitleContainer text-center">
        Join the Lydia Revolution
      </div>
      <p className="text-subColor text-center">Start Using Lydia Coin Today</p>
      <div
        className="flex flex-col md:flex-row flex-wrap justify-center items-center my-8 items-centerz-10"
        data-v-41a2d66f=""
      >
        <button
          className="btnPrimary w-max btnDefault max-h-[48px] flex flex-row items-center justify-center mb-4 md:mb-0 md:mr-2"
          data-v-41a2d66f=""
        >
          <span className=" text-base leading-button">Lydia Wallet</span>
        </button>
        <button
          className="btnSecondary w-max btnDefault max-h-[48px] flex flex-row items-center justify-center"
          onClick={() => {
            navigate("/contact-us");
          }}
        >
          <span className="text-base leading-button">Contact Us</span>
        </button>
      </div>
    </div>
  );
};

export default JoinLydia;