export const TabData = [
  {
    id: "General Terms and Conditions",
    title: "Terms and Conditions",
    subTitle: "General Terms and Conditions",
    content: [
      "Last Modified: August 23, 2024",
      "Welcome to Lydia Coin. By accessing or using our website, products, and services, you agree to comply with and be bound by the following Terms and Conditions. Please read these terms carefully before using our platform. If you do not agree to these terms, you must not use our services.",
      "1. Introduction",
      "These Terms and Conditions ('Terms') govern your use of the Lydia Coin website, services, and any related products or platforms provided by Lydia Coin ('we,' 'us,' 'our'). By using our services, you accept these Terms in full.",
      "2. Eligibility",
      "To use Lydia Coin, you must be at least 18 years of age and possess the legal capacity to enter into binding agreements. By using our services, you confirm that you meet these requirements.",
      "3. Account Registration",
      "Account Creation: To access certain features of our services, you may be required to create an account. You must provide accurate and complete information during the registration process. Account Security: You are responsible for maintaining the confidentiality of your account login information. You agree to notify us immediately of any unauthorized use of your account.",
      "4. Use of Services",
      "Permitted Use: You may use Lydia Coin's services for lawful purposes only. You agree not to engage in any activity that violates any applicable laws, regulations, or these Terms. Prohibited Use: You must not use our services to engage in any illegal, fraudulent, or harmful activities, including but not limited to money laundering, financing of terrorism, or any form of market manipulation.",
      "5. Transactions",
      "Stablecoin Transactions: Lydia Coin allows users to purchase, sell, and trade Lydia Coins. All transactions are final and non-reversible once confirmed on the blockchain.",
      "Transaction Fees: We may charge fees for certain transactions or services. Any applicable fees will be clearly disclosed before you complete a transaction.",
      "Compliance: You agree to comply with all applicable laws and regulations regarding your transactions, including but not limited to anti-money laundering (AML) and know-your-customer (KYC) requirements.",
      "6. Security",
      "Security Measures: We employ advanced security measures to protect your assets and personal information. However, we cannot guarantee the absolute security of your data and assets.",
      "User Responsibility: You are responsible for safeguarding your account credentials, including your private keys and passwords. Lydia Coin is not liable for any loss or damage arising from unauthorized access to your account due to your failure to secure your credentials.",
      "7. Intellectual Property",
      "Ownership: All content, logos, trademarks, and intellectual property related to Lydia Coin are owned by us or our licensors. You may not use, reproduce, or distribute any content from our website without our express written permission.",
      "License: We grant you a limited, non-exclusive, non-transferable license to access and use our services for personal, non-commercial purposes, subject to these Terms.",
      "8. Limitation of Liability",
      "No Warranty: Lydia Coin provides its services \"as is\" and \"as available\" without any warranties of any kind, whether express or implied.",
      "Limitation: To the fullest extent permitted by law, Lydia Coin shall not be liable for any direct, indirect, incidental, special, or consequential damages arising from your use of our services, even if we have been advised of the possibility of such damages.",
      "9. Indemnification",
      "You agree to indemnify and hold Lydia Coin, its affiliates, officers, directors, employees, and agents harmless from any claims, losses, liabilities, damages, costs, or expenses (including legal fees) arising from your use of our services or breach of these Terms.",
      "10. Privacy Policy",
      "Your use of our services is also governed by our Privacy Policy, which describes how we collect, use, and protect your personal information. By using Lydia Coin, you consent to the practices described in our Privacy Policy.",
      "11. Changes to Terms",
      "Lydia Coin reserves the right to modify these Terms at any time. Any changes will be effective immediately upon posting the updated Terms on our website. Your continued use of our services after any such changes constitutes your acceptance of the new Terms.",
      "12. Termination",
      "Suspension or Termination: We may suspend or terminate your access to Lydia Coin's services at any time, without prior notice, for any reason, including but not limited to violations of these Terms.",
      "Effect of Termination: Upon termination, your right to use our services will immediately cease. Any provisions of these Terms that by their nature should survive termination will continue to apply.",
      "13. Governing Law and Jurisdiction",
      "These Terms are governed by and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law principles. Any disputes arising from or relating to these Terms or your use of Lydia Coin’s services will be resolved exclusively in the courts of [Your Jurisdiction].",
      "14. Contact Information",
      "If you have any questions or concerns about these Terms, please contact us at:",
      "Email: [support@lydiacoin.com]",
      "Address: [Your Company's Address]",
      "15. Entire Agreement",
      "These Terms, together with our Privacy Policy and any other legal notices published on our website, constitute the entire agreement between you and Lydia Coin regarding your use of our services and supersede any prior agreements."
    ],
  },
  {
    id: "US Dollar-Backed Stablecoin Terms and Conditions",
    title: "Terms and Conditions",
    subTitle: "US Dollar-Backed Stablecoin Terms and Conditions",
    content: [
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
      "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
      "Curabitur pretium tincidunt lacus. Nulla gravida orci a odio. Nullam varius, turpis et commodo pharetra, est eros bibendum elit.",
      "Integer in metus vel justo aliquet bibendum. Praesent ut lectus at libero vehicula facilisis.",
      "Vivamus pharetra, orci vel facilisis scelerisque, orci lorem tincidunt nunc, euismod scelerisque justo augue a quam.",
      "Morbi interdum velit et purus egestas, sit amet fermentum ipsum dapibus. Sed tempus dui eget lacus sagittis tincidunt.",
      "Fusce cursus neque nec ante fermentum, sit amet accumsan turpis vulputate. Cras imperdiet dolor vel arcu volutpat, id suscipit libero gravida.",
      "Suspendisse potenti. Aenean sed nisi malesuada, sagittis est et, ullamcorper lorem. Nulla facilisi.",
      "Pellentesque consectetur justo et sem consequat, at hendrerit justo blandit. Donec cursus arcu non felis feugiat dictum.",
      "Aliquam erat volutpat. Maecenas condimentum turpis ut est pharetra, eu viverra dui tincidunt.",
      "Ut sit amet magna malesuada, luctus mi eget, consectetur mauris. Duis sit amet elit nec libero vehicula egestas.",
      "Curabitur dignissim dolor ac lorem gravida, et posuere tortor egestas. Vivamus ultricies urna sit amet ex ullamcorper congue.",
      "Proin sagittis dolor ut ante gravida, ut feugiat urna vehicula. In hac habitasse platea dictumst.",
      "Vestibulum luctus erat euismod tortor gravida, at tempor ex dictum. Integer sit amet velit vel lectus condimentum venenatis."
    ]
  },
  {
    id: "Lydia Gold Terms and Conditions",
    title: "Terms and Conditions",
    subTitle: "Lydia Gold Terms and Conditions",
    content: ["Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
      "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
      "Curabitur pretium tincidunt lacus. Nulla gravida orci a odio. Nullam varius, turpis et commodo pharetra, est eros bibendum elit.",
      "Integer in metus vel justo aliquet bibendum. Praesent ut lectus at libero vehicula facilisis.",
      "Vivamus pharetra, orci vel facilisis scelerisque, orci lorem tincidunt nunc, euismod scelerisque justo augue a quam.",
      "Morbi interdum velit et purus egestas, sit amet fermentum ipsum dapibus. Sed tempus dui eget lacus sagittis tincidunt.",
      "Fusce cursus neque nec ante fermentum, sit amet accumsan turpis vulputate. Cras imperdiet dolor vel arcu volutpat, id suscipit libero gravida.",
      "Suspendisse potenti. Aenean sed nisi malesuada, sagittis est et, ullamcorper lorem. Nulla facilisi.",
      "Pellentesque consectetur justo et sem consequat, at hendrerit justo blandit. Donec cursus arcu non felis feugiat dictum.",
      "Aliquam erat volutpat. Maecenas condimentum turpis ut est pharetra, eu viverra dui tincidunt.",
      "Ut sit amet magna malesuada, luctus mi eget, consectetur mauris. Duis sit amet elit nec libero vehicula egestas.",
      "Curabitur dignissim dolor ac lorem gravida, et posuere tortor egestas. Vivamus ultricies urna sit amet ex ullamcorper congue.",
      "Proin sagittis dolor ut ante gravida, ut feugiat urna vehicula. In hac habitasse platea dictumst.",
      "Vestibulum luctus erat euismod tortor gravida, at tempor ex dictum. Integer sit amet velit vel lectus condimentum venenatis."
    ]
  },
  {
    id: "Privacy Policy",
    title: "Terms and Conditions",
    subTitle: "Privacy Policy",
    content: ["Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
      "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
      "Curabitur pretium tincidunt lacus. Nulla gravida orci a odio. Nullam varius, turpis et commodo pharetra, est eros bibendum elit.",
      "Integer in metus vel justo aliquet bibendum. Praesent ut lectus at libero vehicula facilisis.",
      "Vivamus pharetra, orci vel facilisis scelerisque, orci lorem tincidunt nunc, euismod scelerisque justo augue a quam.",
      "Morbi interdum velit et purus egestas, sit amet fermentum ipsum dapibus. Sed tempus dui eget lacus sagittis tincidunt.",
      "Fusce cursus neque nec ante fermentum, sit amet accumsan turpis vulputate. Cras imperdiet dolor vel arcu volutpat, id suscipit libero gravida.",
      "Suspendisse potenti. Aenean sed nisi malesuada, sagittis est et, ullamcorper lorem. Nulla facilisi.",
      "Pellentesque consectetur justo et sem consequat, at hendrerit justo blandit. Donec cursus arcu non felis feugiat dictum.",
      "Aliquam erat volutpat. Maecenas condimentum turpis ut est pharetra, eu viverra dui tincidunt.",
      "Ut sit amet magna malesuada, luctus mi eget, consectetur mauris. Duis sit amet elit nec libero vehicula egestas.",
      "Curabitur dignissim dolor ac lorem gravida, et posuere tortor egestas. Vivamus ultricies urna sit amet ex ullamcorper congue.",
      "Proin sagittis dolor ut ante gravida, ut feugiat urna vehicula. In hac habitasse platea dictumst.",
      "Vestibulum luctus erat euismod tortor gravida, at tempor ex dictum. Integer sit amet velit vel lectus condimentum venenatis."
    ]
  },
  {
    id: "Cookie Policy",
    title: "Terms and Conditions",
    subTitle: "Cookie Policy",
    content: [
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
      "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
      "Curabitur pretium tincidunt lacus. Nulla gravida orci a odio. Nullam varius, turpis et commodo pharetra, est eros bibendum elit.",
      "Integer in metus vel justo aliquet bibendum. Praesent ut lectus at libero vehicula facilisis.",
      "Vivamus pharetra, orci vel facilisis scelerisque, orci lorem tincidunt nunc, euismod scelerisque justo augue a quam.",
      "Morbi interdum velit et purus egestas, sit amet fermentum ipsum dapibus. Sed tempus dui eget lacus sagittis tincidunt.",
      "Fusce cursus neque nec ante fermentum, sit amet accumsan turpis vulputate. Cras imperdiet dolor vel arcu volutpat, id suscipit libero gravida.",
      "Suspendisse potenti. Aenean sed nisi malesuada, sagittis est et, ullamcorper lorem. Nulla facilisi.",
      "Pellentesque consectetur justo et sem consequat, at hendrerit justo blandit. Donec cursus arcu non felis feugiat dictum.",
      "Aliquam erat volutpat. Maecenas condimentum turpis ut est pharetra, eu viverra dui tincidunt.",
      "Ut sit amet magna malesuada, luctus mi eget, consectetur mauris. Duis sit amet elit nec libero vehicula egestas.",
      "Curabitur dignissim dolor ac lorem gravida, et posuere tortor egestas. Vivamus ultricies urna sit amet ex ullamcorper congue.",
      "Proin sagittis dolor ut ante gravida, ut feugiat urna vehicula. In hac habitasse platea dictumst.",
      "Vestibulum luctus erat euismod tortor gravida, at tempor ex dictum. Integer sit amet velit vel lectus condimentum venenatis."

    ]
  },
  {
    id: "Market Manipulation Protection",
    title: "Terms and Conditions",
    subTitle: "Market Manipulation Protection",
    content: [
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
      "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
      "Curabitur pretium tincidunt lacus. Nulla gravida orci a odio. Nullam varius, turpis et commodo pharetra, est eros bibendum elit.",
      "Integer in metus vel justo aliquet bibendum. Praesent ut lectus at libero vehicula facilisis.",
      "Vivamus pharetra, orci vel facilisis scelerisque, orci lorem tincidunt nunc, euismod scelerisque justo augue a quam.",
      "Morbi interdum velit et purus egestas, sit amet fermentum ipsum dapibus. Sed tempus dui eget lacus sagittis tincidunt.",
      "Fusce cursus neque nec ante fermentum, sit amet accumsan turpis vulputate. Cras imperdiet dolor vel arcu volutpat, id suscipit libero gravida.",
      "Suspendisse potenti. Aenean sed nisi malesuada, sagittis est et, ullamcorper lorem. Nulla facilisi.",
      "Pellentesque consectetur justo et sem consequat, at hendrerit justo blandit. Donec cursus arcu non felis feugiat dictum.",
      "Aliquam erat volutpat. Maecenas condimentum turpis ut est pharetra, eu viverra dui tincidunt.",
      "Ut sit amet magna malesuada, luctus mi eget, consectetur mauris. Duis sit amet elit nec libero vehicula egestas.",
      "Curabitur dignissim dolor ac lorem gravida, et posuere tortor egestas. Vivamus ultricies urna sit amet ex ullamcorper congue.",
      "Proin sagittis dolor ut ante gravida, ut feugiat urna vehicula. In hac habitasse platea dictumst.",
      "Vestibulum luctus erat euismod tortor gravida, at tempor ex dictum. Integer sit amet velit vel lectus condimentum venenatis."
    ]
  },
];
