import { Link } from "react-router-dom";
import { useState, useRef, useEffect } from "react";

import DropdownMenu from "../../pages/Reusable/Dropdown";
import { LydiaLogo } from "../../constants/assets";

import "./Header.css";

const Header = () => {
  const [eurDropdown, setEurDropdown] = useState(false);
  const [companyDropdown, setCompanyDropdown] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const eurRef = useRef<HTMLDivElement>(null);
  const companyRef = useRef<HTMLDivElement>(null);
  const navRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    // Check screen size to determine if it's mobile
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust breakpoint as needed
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleDropdown = (
    setDropdown: React.Dispatch<React.SetStateAction<boolean>>,
    show: boolean
  ) => {
    setDropdown(show);
  };

  return (
    <nav
      className={`font-[Montserrat] flex fixed top-0 left-0 w-full bg-white shadow-md z-[9999] py-[11px] min-h-[4rem] pr-[16px] gh-navigation !h-auto`}
    >
      <div className="container mx-auto flex justify-between md:justify-start max-w-screen-xl !gap-[20px]">
        {/* Logo */}
        <div className="NavLogo flex items-center ml-[16px] max-w-max gap-[8px]">
          <Link
            to="/"
            className="!text-[#003a60] text-[18px] font-bold uppercase w-max mr-[16px]"
          >
            <img src={LydiaLogo} alt="Logo" className="!h-[42px]" />
          </Link>
        </div>

        {/* Hamburger Icon for Mobile */}
        <button
          className="block md:!hidden text-[#003a60] focus:outline-none ml-auto gh-burger !mr-0"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <svg
            className="w-[24px] h-[24px] !block"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d={
                isMenuOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"
              }
            />
          </svg>
        </button>

        {/* Desktop and Mobile Menu */}
        <div
          className={`ml-[16px] flex-col md:!flex-row md:!flex w-0 md:w-5/6 justify-between items-center ${
            isMenuOpen ? "!flex" : "hidden"
          }`}
        >
          <ul
            ref={navRef}
            className={`custom-scrollbar !flex-col md:!flex-row md:!flex items-start lg:items-center space-y-[16px] md:space-y-0 md:space-x-[24px] text-[#003a60] text-[14px] text-bold absolute md:!relative bg-white md:bg-transparent w-full md:w-auto left-0 top-full md:top-auto p-[16px] md:p-0 transition-transform duration-200 ease-in-out ${
              isMenuOpen ? "flex" : "hidden"
            } gap-[8px] md:gap-[12px]`}
          >
            <li
              className="relative w-[51.58px]"
              onMouseEnter={
                !isMobile
                  ? () => handleDropdown(setEurDropdown, true)
                  : undefined
              }
              onMouseLeave={
                !isMobile
                  ? () => handleDropdown(setEurDropdown, false)
                  : undefined
              }
            >
              <button
                onClick={
                  isMobile
                    ? () => handleDropdown(setEurDropdown, !eurDropdown)
                    : undefined
                }
                className="relative hover:font-bold font-medium flex items-center uppercase cursor-pointer !tracking-[0.84px] dropdown-container toggle-button"
              >
                Lyd
                <svg
                  className={`w-[16px] h-[16px] ml-[4px] transition-transform duration-200 ${
                    eurDropdown ? "rotate-180" : ""
                  }`}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>
              <DropdownMenu
                setIsMenuOpen={setIsMenuOpen}
                isOpen={eurDropdown}
                onClickOutside={() => setEurDropdown(false)}
                isMobile={isMobile}
                items={[
                  { label: "About", href: "/about-lyd" },
                  { label: "Use Cases", href: "/use-cases" },
                  { label: "Transparency", href: "/transparency" },
                ]}
                ref={eurRef}
              />
            </li>

            <li className="w-[80px]">
              <Link
                to="/partners"
                className="hover:font-bold font-medium uppercase cursor-pointer transition-font-weight text-[#003a60] !tracking-[0.84px]"
              >
                Partners
              </Link>
            </li>

            <li
              className="relative"
              onMouseEnter={
                !isMobile
                  ? () => handleDropdown(setCompanyDropdown, true)
                  : undefined
              }
              onMouseLeave={
                !isMobile
                  ? () => handleDropdown(setCompanyDropdown, false)
                  : undefined
              }
            >
              <button
                onClick={
                  isMobile
                    ? () => handleDropdown(setCompanyDropdown, !companyDropdown)
                    : undefined
                }
                className="hover:font-bold font-medium flex items-center uppercase cursor-pointer !tracking-[0.84px] toggle-button"
              >
                Company
                <svg
                  className={`w-[16px] h-[16px] ml-[4px] transition-transform duration-200 ${
                    companyDropdown ? "rotate-180" : ""
                  }`}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>
              <DropdownMenu
                setIsMenuOpen={setIsMenuOpen}
                isOpen={companyDropdown}
                onClickOutside={() => setCompanyDropdown(false)}
                isMobile={isMobile}
                items={[
                  { label: "FAQ", href: "/faq" },
                  { label: "Blog", href: "/blog" },
                  { label: "Contact Us", href: "/contact-us" },
                  {
                    label: "Terms and Conditions",
                    href: "/terms-and-conditions",
                  },
                ]}
                ref={companyRef}
              />
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
