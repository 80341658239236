import React from "react";
import {
  LydiaCoin2,
  UseCase1,
  UseCase2,
  UseCase3,
  UseCase4,
} from "../../constants/assets";
import JoinLydia from "../../constants/JoinLydia";
import HelmetComponent from "../../component/Reusable/HelmetComponent";
const UseCases: React.FC = () => {
  const Slide = [
    {
      title: "Effortless Cross-Border Transactions",
      description:
        "Facilitate quick and affordable cross-border payments with Lydia Stable Coin. Unlike traditional banking systems, which often involve high fees and lengthy processing times, our stable coin allows for seamless international transactions. This is especially beneficial for businesses and individuals who need to send money abroad frequently, as it reduces costs and eliminates delays.",
      imgSrc: UseCase1,
      reverse: false,
      width: 350,
      height: 350,
      bgWhite: true,
    },
    {
      title: "Hedging Against Volatility",
      description:
        "Protect your wealth during periods of market volatility by converting other cryptocurrencies into Lydia Stable Coin. This allows you to hedge against sharp price swings and maintain the value of your assets, providing peace of mind in uncertain market conditions.",
      imgSrc: UseCase2,
      reverse: true,
      bgWhite: false,
    },
    {
      title: "Protect and Grow Your Wealth",
      description:
        "In an era of economic uncertainty, Lydia Stable Coin offers a safe haven for your savings and investments. With its stable value, Lydia Stable Coin shields your assets from the volatility of the cryptocurrency market and inflation. Whether you’re saving for the future or looking for a stable store of value, Lydia Stable Coin provides a secure digital alternative to traditional savings accounts. Plus, it’s easy to convert Lydia Stable Coin back into fiat currency whenever you need it, giving you full control over your financial future.",
      imgSrc: UseCase3,
      reverse: false,
      bgWhite: true,
    },
    {
      title: "Send Money Home Instantly",
      description:
        "Lydia Stable Coin simplifies the process of sending remittances to family and friends in other countries. Traditional remittance services can be expensive and slow, with hidden fees that eat into the amount your loved ones receive. Lydia Stable Coin changes that by providing a fast, transparent, and low-cost solution for sending money abroad. Your loved ones can receive their funds instantly, with no hidden fees, making Lydia Stable Coin the ideal choice for remittances.",
      imgSrc: UseCase4,
      reverse: true,
      bgWhite: false,
    },
  ];

  return (
    <>
      <HelmetComponent
        title="Lydia Coin - Use Cases"
        description="Explore the various use cases of Lydia Coin in the digital economy."
        keywords="Lydia Coin, Use Cases, Digital Economy, Cryptocurrency"
        ogTitle="Lydia Coin - Use Cases"
        ogDescription="Explore the various use cases of Lydia Coin in the digital economy."
        ogImage="https://lydia-coin-landing-page.vercel.app"
        ogUrl="https://lydia-coin-landing-page.vercel.app"
        twitterTitle="Lydia Coin - Use Cases"
        twitterDescription="Explore the various use cases of Lydia Coin in the digital economy."
        twitterImage="https://lydia-coin-landing-page.vercel.app"
      />
      {/*New Usecase page*/}
      <div className="b-container-big pt-28 py-8">
        <div className="flex flex-col-reverse md:flex-row justify-between items-center  ">
          <div className="md:w-1/2">
            <h2 className="lydiaTitleContainer flex flex-col mb-4">
              Empowering the Future of Finance
            </h2>
            <p className="text-subColor mb-0 opacity-6 pt-6 ">
              Lydia Stable Coin is more than just a stable coin; it’s a
              versatile digital asset designed to meet the needs of today’s
              dynamic financial landscape. With its stable value, security, and
              ease of use, Lydia Stable Coin opens up a world of possibilities
              for individuals and businesses alike.
            </p>
          </div>
          <div className="md:w-1/3 mt-8 md:mt-0 flex justify-center">
            <img
              src={LydiaCoin2}
              alt="LYD Stablecoin Logo"
              className="scene"
              width={400}
              height={400}
            />
          </div>
        </div>
      </div>
      <div className="block m-auto mt-[100px]">
        <section className="b-container-big md:flex flex-col items-center">
          <div className="relative w-full">
            <div className="flex flex-col gap-[90px] md:gap-[120px] md:pb-[54px] pb-[64px]">
              {Slide.map((slide, index) => (
                <div
                  key={index}
                  className={`b-container-big ${slide.bgWhite ? "bg-white" : "bg-#fafafb"
                    } md:!p-8 rounded-xl w-full md:flex ${slide.reverse ? "md:flex-row-reverse" : "md:flex-row "
                    } items-center flex-col justify-between`}
                >
                  <div className="landingHeaderImg m-auto md:m-0 mb-10 md:mb-0">
                    <img
                      src={slide.imgSrc}
                      alt={slide.title}
                      className="scene m-auto"
                      width={slide.width ? slide.width : 400}
                      height={slide.height ? slide.height : 400}
                    />
                  </div>
                  <div className="md:w-1/2 flex flex-col justify-center">
                    <h1 className="lydiaSubtitleContainer mb-2">
                      {slide.title}
                    </h1>
                    <h4 className="text-subColor max-w-full">
                      {slide.description}
                    </h4>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
      <JoinLydia />
    </>
  );
};

export default UseCases;
