import { Helmet } from "react-helmet-async";

interface HelmetProps {
  title?: string;
  description?: string;
  keywords?: string;
  ogTitle?: string;
  ogDescription?: string;
  ogImage?: string;
  ogUrl?: string;
  twitterCard?: string;
  twitterTitle?: string;
  twitterDescription?: string;
  twitterImage?: string;
}

const HelmetComponent: React.FC<HelmetProps> = ({
  title = "Lydia Coin - default",
  description = "Default description",
  keywords = "default, keywords",
  ogTitle = "Default OG Title",
  ogDescription = "Default OG Description",
  ogImage = "default-image-url.jpg",
  ogUrl = "https://lydia-coin-landing-page.vercel.app",
  twitterCard = "summary_large_image",
  twitterTitle = "Default Twitter Title",
  twitterDescription = "Default Twitter Description",
  twitterImage = "default-twitter-image-url.jpg",
}) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />

      {/* Open Graph Meta Tags */}
      <meta property="og:title" content={ogTitle} />
      <meta property="og:description" content={ogDescription} />
      <meta property="og:image" content={ogImage} />
      <meta property="og:url" content={ogUrl} />
      <meta property="og:type" content="website" />

      {/* Twitter Card Meta Tags */}
      <meta name="twitter:card" content={twitterCard} />
      <meta name="twitter:title" content={twitterTitle} />
      <meta name="twitter:description" content={twitterDescription} />
      <meta name="twitter:image" content={twitterImage} />

      {/* Robots Meta Tag */}
      <meta name="robots" content="index, follow" />
    </Helmet>
  );
};

export default HelmetComponent;
