import moment from "moment";
import { Link } from "react-router-dom";
import { LydiaCoin1bg } from "../../constants/assets";

export interface BlogCardsProps {
  data: {
    blogTitle?: string;
    blogDate?: string;
    blogSubDescription?: string;
    blogDescription?: string;
    feature_image?: string;
    feature_image_alt?: string;
    category?: string;
    title?: string;
    html?: string;
    slug?: string;
    id?: string;
    primary_tag?: object;
    excerpt?: string;
    published_at: string;
  };
}

export const BlogCard = (props: BlogCardsProps) => {
  const { data } = props;

  return (
    <>
      <div className="md:mb-[64px] mb-4">
        <div className="observerElement flex flex-col md:justify-center justify-between md:items-start items-center font-inter animate-delay">
          <Link to={`/blog/${data?.slug}`}>
            <img
              alt={data?.feature_image_alt || "Lydia"}
              src={data.feature_image ? data?.feature_image : LydiaCoin1bg}
              loading="lazy"
              className="md:w-[384px] md:h-[211px] w-[696px] h-[300px] rounded-[12px] cursor-pointer object-contain"
            />
          </Link>
          <div className="flex flex-col items-start md:mr-0  min-w-full md:max-w-[200px]">
            <div className="flex flex-row items-center mt-[20px] mb-[12px]">
              <div className="articleTag truncate-multiline-title">
                {" "}
                {(data?.primary_tag as { name?: string })?.name || "Lydia BLockchain"}
              </div>
              <div className="text-main07 text-[16px] font-normal truncate-multiline-title">
                {moment(data?.published_at).format("D MMM YYYY")}
              </div>
            </div>
            <Link to={`/blog/${data?.slug}`}>
              <div className="text-[20px] font-semibold max-w-[400px] cursor-pointer md:leading-[28px] leading-[18.2px] truncate-multiline-title">
                {data?.title}
              </div>
            </Link>
            <div className="max-w-[400px] md:flex hidden mt-3 text-main07 text-[16px] font-normal truncate-multiline">
              {" "}
              {data?.excerpt}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
