import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLinkedinIn,
  faInstagram,
  faTwitter,
  faFacebook,
} from "@fortawesome/free-brands-svg-icons";
import InnerHTML from "dangerously-set-html-content";
import { LydiaCoin1bg } from "../../constants/assets";
import { BlogCard } from "../../component/card/BlogCard";
import HelmetComponent from "../../component/Reusable/HelmetComponent";
import { useParams } from "react-router-dom";
import axiosInstance from "../../lib/axiosInstance";
import moment from "moment";
import BlogDetailLoading from "./BlogDetailLoading";
import { dynamicVersion } from "../../component/Reusable/reusable";

type bloginterface = {
  description: any;
  title: string;
  content: string;
  createdAt: string;
  updatedAt: string;
  previewImage: string;
  slug: string;
  feature_image: string;
  feature_image_alt: string;
  html: string;
  published_at: string;
  primary_tag: {
    name: string;
    slug: string;
  };
  author: [
    {
      _id: string;
      profilePic: string;
      userName: string;
      name: string;
    }
  ];
  id: string;
};

export const BlogDetail: React.FC = (): JSX.Element => {
  const { id } = useParams();
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [relatedData, setRelatedData] = useState<bloginterface[]>();
  const [htmlData, setHtmlData] = useState("");

  const fetchData = async (id: string | undefined) => {
    try {
      const res = await axiosInstance.get(
        `/slug/${id}/?key=${process.env.REACT_APP_API_KEY}${process.env.REACT_APP_API_TAG}`
      );
      setData(res.data?.posts);
      let htmlData = `
      ${res?.data.posts[0].html}
      <link href="${
        process.env.REACT_APP_CMS_URL
      }/public/cards.min.css?v=${dynamicVersion()}" rel="stylesheet"></link>
      <link href="${
        process.env.REACT_APP_CMS_URL
      }/assets/built/screen.css?v=${dynamicVersion()}" rel="stylesheet"></link>
      <link href="https://cdn.jsdelivr.net/ghost/sodo-search@~1.1/umd/main.css?v=${dynamicVersion()}" rel="stylesheet"></link>
      <script src="${
        process.env.REACT_APP_CMS_URL
      }/public/cards.min.js?v=${dynamicVersion()}" type="text/javascript"></script>
      <script src="${
        process.env.REACT_APP_CMS_URL
      }/assets/built/source.js?v=${dynamicVersion()}" type="text/javascript"></script>
        `;
      setHtmlData(htmlData);
    } catch (err: any) {
      console.log(`Error fetching data: ${err}`);
    } finally {
      setLoading(false);
    }
  };

  const fetchRelatedPosts = async (tag: string, currentPostId: string) => {
    try {
      const res = await axiosInstance.get(
        `/?key=${process.env.REACT_APP_API_KEY}&include=tags&filter=tag:${tag}&limit=5`
      );
      const data = await res?.data?.posts;
      const filteredData: bloginterface[] = data.filter(
        (post: bloginterface) => post.id !== currentPostId
      );
      setRelatedData(filteredData);
      return filteredData;
    } catch (err: any) {
      console.log(`Error fetching data: ${err}`);
      return [];
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) fetchData(id);
  }, [id]);

  useEffect(() => {
    if (data.length > 0 && data[0]?.primary_tag?.slug && data[0]?.id) {
      fetchRelatedPosts(data[0]?.primary_tag?.slug, data[0]?.id);
    }
  }, [data]);

  return (
    <>
      <HelmetComponent
        title="Lydia Coin - Blog Details"
        description="Explore the latest updates, news, and articles on Lydia Coin."
        keywords="Lydia Coin, Blog, Cryptocurrency, News, Updates"
        ogTitle="Lydia Coin - Blog Details"
        ogDescription="Explore the latest updates, news, and articles on Lydia Coin."
        ogImage="https://lydia-coin-landing-page.vercel.app"
        ogUrl="https://lydia-coin-landing-page.vercel.app"
        twitterTitle="Lydia Coin - Blog Details"
        twitterDescription="Explore the latest updates, news, and articles on Lydia Coin."
        twitterImage="https://lydia-coin-landing-page.vercel.app"
      />
      {!loading ? (
        <div className="b-container-big bg-grayBackground !pt-[112px] flex flex-col gap-10 md:gap-[120px]">
          <div className="flex flex-col">
            <div className="flex flex-row items-center md:mb-[12px] mt-[8px] md:mt-0">
              <div className="articleTag">{data[0]?.primary_tag?.name}</div>
              <div className="text-main07 text-[16px] font-normal">
                {" "}
                {moment(data[0]?.published_at).format("D MMM YYYY")}
              </div>
            </div>
            <h1 className="lydiaTitleContainer">{data[0]?.title}</h1>
          </div>
          <div className="flex flex-col">
            <div className="flex flex-col gap-6 items-start">
              <div className="md:h-[498px] h-[300px] mx-auto md:mx-0">
                <img
                  className="w-full h-full object-contain"
                  src={
                    data[0]?.feature_image
                      ? data[0]?.feature_image
                      : LydiaCoin1bg
                  }
                  alt={data[0]?.feature_image_alt}
                />
              </div>
              <div
                className="text-center w-full md:text-left"
                dangerouslySetInnerHTML={{
                  __html: data[0]?.feature_image_caption || "Lydia Coin",
                }}
              />
            </div>
            <div className="mt-20 md:mt-[120px] lg:w-2/3 flex flex-col gap-14">
              {data[0]?.html && (
                <div className="overwrap">
                  <InnerHTML
                    className="html-css-container gh-content gh-canvas gh-article is-body"
                    html={htmlData}
                  />
                </div>
              )}
              <div className="flex flex-col gap-2">
                <p className="text-[16px] text-[#6B7280]">Share on</p>
                <div className="flex flex-row gap-6 text-[#007DFC]">
                  {/* <FontAwesomeIcon icon={faInstagram} className="w-[24px] h-[25px]" /> */}
                  <a
                    href={`https://twitter.com/intent/tweet?text=Check%20out%20this%20blog!&url=${window.location.href}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={faTwitter}
                      className="w-[24px] h-[25px]"
                    />
                  </a>
                  <a
                    href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={faFacebook}
                      className="w-[24px] h-[25px]"
                    />
                  </a>
                  <a
                    href={`https://www.linkedin.com/sharing/share-offsite/?url=${window.location.href}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={faLinkedinIn}
                      className="w-[24px] h-[25px]"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>

          {Array.isArray(relatedData) && relatedData?.length > 0 && (
            <div className="flex flex-col">
              <h1 className="lydiaSubtitleContainer">More Articles</h1>
              <section className="font-inter pt-[46px] md:pb-[56px] pb-[64px] md:pt-[40px]">
                <div className="eurs-container-blog md:grid-cols-3 gap-[16px] flex md:flex-row flex-wrap flex-col items-start font-inter">
                  {relatedData &&
                    relatedData?.map((item, index) => (
                      <div key={index}>
                        <BlogCard data={item} />
                      </div>
                    ))}
                </div>
              </section>
            </div>
          )}
        </div>
      ) : (
        <BlogDetailLoading />
      )}
    </>
  );
};
