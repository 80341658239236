import React, { useEffect, useRef, forwardRef } from "react";
import { Link } from "react-router-dom";

interface DropdownMenuProps {
  setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>,
  isOpen: boolean;
  onClickOutside: () => void;
  items: { href: string; label: string }[];
  isMobile: boolean; // New prop to determine mobile view
}

const DropdownMenu = forwardRef<HTMLDivElement, DropdownMenuProps>(
  ({ setIsMenuOpen, isOpen, onClickOutside, items, isMobile }, ref) => {
    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (
          dropdownRef.current &&
          !dropdownRef.current.contains(event.target as Node) &&
          !(event.target as Element).classList.contains('toggle-button') &&
          !(event.target as Element).closest('.toggle-button')
        ) {
          onClickOutside();
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);

    if (!isOpen) return null;

    return (
      <div className={`${isMobile ? " relative" : "absolute top-full left-0"}`}>
        <div
          className={`w-full min-w-max bg-white rounded-md md:shadow-lg z-10 ${isMobile ? "" : "mt-[30px]"}`}
          ref={dropdownRef}
        >
          {items.map((item, index) => (
            <Link
              onClick={() => {
                onClickOutside();
                setIsMenuOpen(false);

              }}
              key={index}
              to={item.href}
              className="block uppercase px-[16px] py-[8px] text-[14px] hover:bg-gray-100 cursor-pointer transition-font-weight font-medium text-[#003a60] hover:font-bold"
            >
              {item.label}
            </Link>
          ))}
        </div>
      </div>

    );
  }
);

DropdownMenu.displayName = "DropdownMenu";

export default DropdownMenu;
