import React, { useState, useEffect } from "react";

import { BlogCard } from "../../component/card/BlogCard";
import { LydiaCoin1bg } from "../../constants/assets";
import { Link } from "react-router-dom";
import axiosInstance from "../../lib/axiosInstance";
import HelmetComponent from "../../component/Reusable/HelmetComponent";
import moment from "moment";
import Blogloading from "./Blogloading";
import InfiniteScroll from "react-infinite-scroll-component";
import BLogCardLoading from "../../component/card/BLogCardLoading";

export const Blog: React.FC = (): JSX.Element => {
  const [activeTab, setActiveTab] = useState<string>("All");
  const [allBlogs, setAllBlogs] = useState<any[]>([]);
  const [featuredBlog, setFeaturedBlog] = useState<any | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [totalBlogPost, setTotalBlogPost] = useState(0);
  const [animationKey, setAnimationKey] = useState(0);
  const limit = 6;

  const fetchBlogPosts = async (page: number) => {
    try {
      const response = await axiosInstance.get(
        `/?key=${process.env.REACT_APP_API_KEY}${process.env.REACT_APP_API_TAG}&page=${page}&limit=${limit}`
      );

      setTotalBlogPost(response?.data?.meta?.pagination?.total);
      if (response.data?.posts.length === 0) {
        setHasMore(false);
      } else {
        setAllBlogs((prevBlogs) => {
          const newPosts = response.data?.posts.filter(
            (post: any) =>
              !prevBlogs.some((prevPost) => prevPost.id === post.id)
          );
          return [...prevBlogs, ...newPosts];
        });
        const featured = response.data?.posts.find(
          (post: any) => post.featured === true
        );
        setFeaturedBlog(featured || response.data?.posts[0]);
      }
    } catch (err) {
      setError("Failed to fetch blog posts");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBlogPosts(currentPage);
  }, [currentPage]);

  // Handle tab change
  const handleTabChange = (tab: string) => {
    if (tab !== activeTab) {
      setActiveTab(tab);
      setAnimationKey((prev) => prev + 1);
    }
  };

  const filteredBlogData =
    activeTab === "All" ? allBlogs : allBlogs.filter((blog) => blog.featured);

  const skeletonCount = hasMore ? totalBlogPost - allBlogs.length : 0;

  return (
    <>
      <HelmetComponent
        title="Lydia Coin - Blog"
        description="Explore the latest updates, news, and articles on Lydia Coin."
        keywords="Lydia Coin, Blog, Cryptocurrency, News, Updates"
        ogTitle="Lydia Coin - Blog"
        ogDescription="Explore the latest updates, news, and articles on Lydia Coin."
        ogImage="https://lydia-coin-landing-page.vercel.app"
        ogUrl="https://lydia-coin-landing-page.vercel.app"
        twitterTitle="Lydia Coin - Blog"
        twitterDescription="Explore the latest updates, news, and articles on Lydia Coin."
        twitterImage="https://lydia-coin-landing-page.vercel.app"
      />
      {!loading ? (
        <>
          <section className="b-container-big bg-grayBackground pt-28 md:pb-[56px] pb-[60px] font-inter">
            <div className="eurs-container-blog flex flex-col items-start font-inter">
              <div className="flex flex-col items-center">
                <h2 className="lydiaTitleContainer text-center">
                  Explore the stories & insights about Lydia, Stable coin
                  sector, and Web3
                </h2>
              </div>
              <div className="w-full">
                <Link to={`/blog/${featuredBlog?.slug}`}>
                  <div className="flex md:flex-row flex-col justify-center md:items-center items-start font-inter md:mt-[90px] mt-[40px]">
                    <img
                      alt="Month in Review: July 2024"
                      src={featuredBlog?.feature_image || LydiaCoin1bg}
                      className="md:w-1/2  h-[300px] md:h-[598px] object-contain rounded-[12px] cursor-pointer m-auto"
                    />
                    <div className="md:w-1/2 flex flex-col items-start md:ml-8">
                      <div className="flex md:flex-col items-start flex-col-reverse">
                        <div className="flex flex-row items-center md:mb-3 mt-2 md:mt-0">
                          <div className="articleTag">
                            {featuredBlog?.primary_tag?.name || "Featured"}
                          </div>
                          <div className="text-main07 text-base font-normal">
                            {moment(featuredBlog?.published_at).format(
                              "D MMM YYYY"
                            )}
                          </div>
                        </div>
                        <div className="articleTitle !text-[2rem] truncate-multiline">
                          {featuredBlog?.title || ""}
                        </div>
                      </div>
                      <div className="md:block hidden mt-3 text-subColor-1 h-[80px] truncate-multiline">
                        {featuredBlog?.excerpt || ""}
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </section>

          <div className="border-b-2 border-med005 bg-grayBackground md:px-0 px-4">
            <div className="categoryRow flex flex-row items-start justify-center eurs-container-blog">
              {["All", "Featured"].map((tab) => (
                <div key={tab}>
                  <div
                    className={`text-subColor-1 blogCategory ${
                      activeTab === tab
                        ? "mainColor border-b-[4px] border-primary"
                        : ""
                    }`}
                    onClick={() => handleTabChange(tab)}
                  >
                    {tab}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <section className="b-container-big bg-grayBackground font-inter pt-[64px] md:pb-[56px] pb-[64px] md:pt-[60px]">
            <InfiniteScroll
              dataLength={allBlogs.length}
              next={() => setCurrentPage((prev) => prev + 1)}
              hasMore={hasMore}
              loader={
                <div className="flex flex-wrap gap-8">
                  {Array.from({ length: skeletonCount }).map((_, index) => (
                    <BLogCardLoading key={index} />
                  ))}
                </div>
              }
            >
              <div
                key={animationKey}
                className="animation eurs-container-blog md:grid-cols-3 gap-8 flex md:flex-row flex-wrap flex-col items-start font-inter grid grid-flow-row"
              >
                {filteredBlogData
                  .slice(0, currentPage * limit)
                  .map((item, index) => (
                    <div key={index}>
                      <BlogCard data={item} />
                    </div>
                  ))}
              </div>
            </InfiniteScroll>
          </section>
        </>
      ) : (
        <Blogloading />
      )}
    </>
  );
};
