import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import BLogCardLoading from "../../component/card/BLogCardLoading";

const Blogloading: React.FC = (): JSX.Element => {
  return (
    <section className="md:pb-[156px] pb-[70px] px-5">
      <div className="main-container !pt-[98px]">
        <div className="flex flex-col gap-[80px] md:gap-[120px]">
          <div className="sub-container">
            {
              <div className="max-w-[1280px] mx-auto">
                {" "}
                <Skeleton width={"100%"} height={42} count={1.5} />{" "}
              </div>
            }
          </div>
          <div className=" flex justify-center">
            <div className="w-full flex justify-center">
              <div className="w-full !max-w-[1280px]  flex flex-col md:flex-row gap-10 md:gap-[103px] items-center justify-start ">
                <div className="w-[100%]">
                  <Skeleton
                    height={504}
                    width={"100%"}
                    className="!max-w-[100%]"
                  />
                </div>
                <div className="flex flex-col gap-3 md:w-1/2 w-full">
                  <div className="flex items-center gap-[18px]  ">
                    <Skeleton width={137} height={44} borderRadius={65} />

                    <div className="text-sm text-primaryTextColor font-normal">
                      <Skeleton width={49} height={20} borderRadius={65} />
                    </div>
                  </div>
                  <div className="text-heading-3 md:text-heading-1 font-subText text-primaryTextColor md:leading-[76px]">
                    <Skeleton
                      width={"80%"}
                      height={76}
                      className="max-w-[100%]"
                    />
                  </div>
                  <div className="text-subText-4 font-subText text-secondaryTextColor md:mt-3.5">
                    <Skeleton
                      width={"50%"}
                      height={36}
                      className="max-w-[100%]"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full mt-[-40px]">
            <div className="!max-w-[1280px] m-auto grid grid-flow-row">
              <div className="w-full mx-auto gap-[60px] md:grid-cols-3 md:gap-8 grid md:flex-row flex-wrap flex-col items-start ">
                {[1, 2, 3, 4, 5].map((item, index) => (
                  <BLogCardLoading key={index} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Blogloading;
