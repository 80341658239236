import React, { createContext, useContext, useEffect, useState } from "react";
import useTotalSupply from "../hooks/useTotalSupply";

interface TotalSupplyContextType {
  totalSupply: string | null;
  loading: boolean;
  error: string | null;
}

const TotalSupplyContext = createContext<TotalSupplyContextType | undefined>(
  undefined
);

export const TotalSupplyProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { totalSupply, loading, error } = useTotalSupply();
  return (
    <TotalSupplyContext.Provider value={{ totalSupply, loading, error }}>
      {children}
    </TotalSupplyContext.Provider>
  );
};

export const useTotalSupplyContext = () => {
  const context = useContext(TotalSupplyContext);
  if (!context) {
    throw new Error(
      "useTotalSupplyContext must be used within a TotalSupplyProvider"
    );
  }
  return context;
};
