export const formatBigInt = (value: bigint): string => {
  if (typeof value !== "bigint") {
    throw new TypeError("Input must be a BigInt");
  }
  return value.toLocaleString("en-US");
};

export const dynamicVersion = (): number => {
  const now: Date = new Date();
  const hours: number = now.getHours();
  const minutes: string = now.getMinutes().toString().padStart(2, "0");
  const myTime: number = hours * 100 + parseInt(minutes, 10);

  return myTime;
};
