import React from "react";
import Skeleton from "react-loading-skeleton";

const BlogDetailLoading = () => {
  return (
    <>
      <div className="max-w-[1280px] pt-28 lg:mx-32 px-5 flex flex-col gap-10 md:gap-[120px] py-">
        <div className="flex flex-col">
          <div className="flex flex-row gap-2 items-center md:mb-3 mt-2 md:mt-0">
            <Skeleton width={119} height={44} borderRadius={65} />
            <div className="text-subText-3 font-heading-2 text-secondaryTextColor">
              <Skeleton width={90} height={36} borderRadius={65} />
            </div>
          </div>
          {<Skeleton count={1.3} width={"90%"} height={80} />}
          {<Skeleton width={"40%"} height={27} />}
        </div>
        <div className="flex flex-col">
          <div className="flex flex-row gap-6">
            <div className="w-full lg:w-3/4 h-[498px]">
              {<Skeleton width={"100%"} height={498} />}
            </div>
          </div>
          {
            <div className="pt-12 w-3/4 flex flex-col gap-5 pb-[120px]">
              <Skeleton width={"100%"} height={24} count={4} className="mt-2" />{" "}
              <Skeleton width={"100%"} height={24} count={7} className="mt-2" />{" "}
            </div>
          }
        </div>
      </div>
    </>
  );
};

export default BlogDetailLoading;
