import React from 'react'
import Skeleton from 'react-loading-skeleton'

const BLogCardLoading = () => {
  return (
      <div className="">
          <div className="py-10 flex flex-col  w-[372px]">
              <div className="flex flex-col justify-center items-start gap-2">
                  <div className="w-full">
                      {" "}
                      <Skeleton
                          height={211}
                          width={"100%"}
                          borderRadius={10}
                          className="!max-w-[100%]"
                      />{" "}
                  </div>

                  <div className="flex flex-col items-start gap-[8px] md:mr-0 mr-2 min-w-full max-w-[200px]">
                      <div className="flex items-center gap-[18px] w-full">
                          <Skeleton
                              width={147}
                              height={30}
                              borderRadius={65}
                          />

                          <div className="text-sm text-primaryTextColor font-normal">
                              <Skeleton
                                  width={49}
                                  height={20}
                                  borderRadius={65}
                              />
                          </div>
                      </div>
                      <div className="w-full">
                          <Skeleton height={38} width={"100%"} />
                      </div>
                      <div className="w-full">
                          <Skeleton
                              count={3}
                              width={"100%"}
                              height={20}
                              className="!max-w-[100%]"
                          />
                      </div>
                  </div>
              </div>
          </div>
      </div>
  )
}

export default BLogCardLoading