import {
  AboutBenefit,
  AboutDefine1,
  AboutDefine2,
  AboutDefine3,
  AboutDefine4,
} from "../../constants/assets";

import JoinLydia from "../../constants/JoinLydia";
import HelmetComponent from "../../component/Reusable/HelmetComponent";

import "./About.css";

const About = () => {
  return (
    <>
      <HelmetComponent
        title="Lydia Coin - About"
        description="Learn more about Lydia Coin, its mission, and its team."
        keywords="Lydia Coin, About, Mission, Team"
        ogTitle="Lydia Coin - About"
        ogDescription="Learn more about Lydia Coin, its mission, and its team."
        ogImage="https://lydia-coin-landing-page.vercel.app"
        ogUrl="https://lydia-coin-landing-page.vercel.app"
        twitterTitle="Lydia Coin - About"
        twitterDescription="Learn more about Lydia Coin, its mission, and its team."
        twitterImage="https://lydia-coin-landing-page.vercel.app"
      />
      <section className="b-container-big w-full flex justify-center items-center pt-28 lg:pt-28 pb-20">
        <div className="flex flex-col max-w-[1100px] items-center gap-[10px]">
          <div className="text-center lydiaTitleContainer">
            About Lydia Stable Coin
          </div>
          <div className="text-subColor max-w-[986px] text-center">
            At Lydia Coin, our mission is to bring stability, security, and
            transparency to the world of digital finance. We believe that
            everyone deserves access to a reliable and stable digital currency,
            one that empowers them to navigate the evolving financial landscape
            with confidence.
          </div>
        </div>
      </section>
      <section
        className="b-container-big pb-16 lg:pb-0 lg:justify-between m-auto"
        data-v-be2c11a6=""
      >
        <div
          className="lg:pt-[100px] md:max-w-[485px] lg:max-w-full !pt-0 m-auto"
          data-v-be2c11a6=""
        >
          <div
            className="flex flex-col justify-center items-center hidden lg:flex gap-[10px]"
            data-v-be2c11a6=""
          >
            <div className="lydiaSubtitleContainer" data-v-be2c11a6="">
              Decent benefits for every party
            </div>
            <div className="text-center text-subColor max-w-[974px]  ">
              Lorem ipsum dolor sit amet consectetur. Non lacus turpis mattis
              lacinia. Duis pharetra elementum massa rhoncus volutpat risus
              fusce. Turpis mattis tincidunt gravida nisl. Viverra cursus sit
              auctor nisl ultrices est ipsum.
            </div>
          </div>
          <div
            className="b-container-column flex flex-col lg:flex-row justify-center lg:justify-between mt-14"
            data-v-be2c11a6=""
          >
            <div
              className="text-subColor-1 mt-8 lg:mt-10 lg:w-full lg:max-w-[280px]"
              data-v-be2c11a6=""
            >
              <h2
                className="text-[#003a60] text-2xl font-semibold"
                data-v-be2c11a6=""
              >
                Private Customers
              </h2>
              <div
                className="mt-5 flex items-center flex-row text-base"
                data-v-be2c11a6=""
              >
                <div className="mr-3" data-v-be2c11a6="">
                  <svg
                    data-v-be2c11a6=""
                    fill="none"
                    height="20"
                    viewBox="0 0 20 20"
                    width="20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_1828_943)">
                      <path
                        d="M18.3334 9.23257V9.99924C18.3323 11.7963 17.7504 13.5448 16.6745 14.9841C15.5985 16.4234 14.0861 17.4763 12.3628 17.9858C10.6395 18.4953 8.79774 18.4342 7.11208 17.8114C5.42642 17.1886 3.98723 16.0377 3.00915 14.5301C2.03108 13.0226 1.56651 11.2393 1.68475 9.44616C1.80299 7.65304 2.49769 5.94617 3.66525 4.58013C4.83281 3.21409 6.41068 2.26205 8.16351 1.86602C9.91635 1.46999 11.7502 1.65118 13.3917 2.38257"
                        stroke="#2568E4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.66667"
                      />
                      <path
                        d="M18.3333 3.33398L10 11.6757L7.5 9.17565"
                        stroke="#2568E4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.66667"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1828_943">
                        <rect fill="white" height="20" width="20" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>{" "}
                Most transparent stablecoin
              </div>
              <div
                className="mt-3 flex items-center flex-row text-base"
                data-v-be2c11a6=""
              >
                <div className="mr-3" data-v-be2c11a6="">
                  <svg
                    data-v-be2c11a6=""
                    fill="none"
                    height="20"
                    viewBox="0 0 20 20"
                    width="20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_1828_943)">
                      <path
                        d="M18.3334 9.23257V9.99924C18.3323 11.7963 17.7504 13.5448 16.6745 14.9841C15.5985 16.4234 14.0861 17.4763 12.3628 17.9858C10.6395 18.4953 8.79774 18.4342 7.11208 17.8114C5.42642 17.1886 3.98723 16.0377 3.00915 14.5301C2.03108 13.0226 1.56651 11.2393 1.68475 9.44616C1.80299 7.65304 2.49769 5.94617 3.66525 4.58013C4.83281 3.21409 6.41068 2.26205 8.16351 1.86602C9.91635 1.46999 11.7502 1.65118 13.3917 2.38257"
                        stroke="#2568E4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.66667"
                      />
                      <path
                        d="M18.3333 3.33398L10 11.6757L7.5 9.17565"
                        stroke="#2568E4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.66667"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1828_943">
                        <rect fill="white" height="20" width="20" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>{" "}
                Swap to any crypto
              </div>
              <div
                className="mt-3 flex items-center flex-row text-base"
                data-v-be2c11a6=""
              >
                <div className="mr-3" data-v-be2c11a6="">
                  <svg
                    data-v-be2c11a6=""
                    fill="none"
                    height="20"
                    viewBox="0 0 20 20"
                    width="20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_1828_943)">
                      <path
                        d="M18.3334 9.23257V9.99924C18.3323 11.7963 17.7504 13.5448 16.6745 14.9841C15.5985 16.4234 14.0861 17.4763 12.3628 17.9858C10.6395 18.4953 8.79774 18.4342 7.11208 17.8114C5.42642 17.1886 3.98723 16.0377 3.00915 14.5301C2.03108 13.0226 1.56651 11.2393 1.68475 9.44616C1.80299 7.65304 2.49769 5.94617 3.66525 4.58013C4.83281 3.21409 6.41068 2.26205 8.16351 1.86602C9.91635 1.46999 11.7502 1.65118 13.3917 2.38257"
                        stroke="#2568E4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.66667"
                      />
                      <path
                        d="M18.3333 3.33398L10 11.6757L7.5 9.17565"
                        stroke="#2568E4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.66667"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1828_943">
                        <rect fill="white" height="20" width="20" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>{" "}
                Spend with bank cards
              </div>
              <div
                className="mt-3 flex items-center flex-row text-base"
                data-v-be2c11a6=""
              >
                <div className="mr-3" data-v-be2c11a6="">
                  <svg
                    data-v-be2c11a6=""
                    fill="none"
                    height="20"
                    viewBox="0 0 20 20"
                    width="20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_1828_943)">
                      <path
                        d="M18.3334 9.23257V9.99924C18.3323 11.7963 17.7504 13.5448 16.6745 14.9841C15.5985 16.4234 14.0861 17.4763 12.3628 17.9858C10.6395 18.4953 8.79774 18.4342 7.11208 17.8114C5.42642 17.1886 3.98723 16.0377 3.00915 14.5301C2.03108 13.0226 1.56651 11.2393 1.68475 9.44616C1.80299 7.65304 2.49769 5.94617 3.66525 4.58013C4.83281 3.21409 6.41068 2.26205 8.16351 1.86602C9.91635 1.46999 11.7502 1.65118 13.3917 2.38257"
                        stroke="#2568E4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.66667"
                      />
                      <path
                        d="M18.3333 3.33398L10 11.6757L7.5 9.17565"
                        stroke="#2568E4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.66667"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1828_943">
                        <rect fill="white" height="20" width="20" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>{" "}
                Get passive yield in in DeFi
              </div>
            </div>
            <img
              alt="Institutional crypto"
              className="order-first lg:order-none max-h-[355px] lg:max-h-full object-contain"
              data-v-be2c11a6=""
              src={AboutBenefit}
            />
            <div
              className="flex flex-col mt-6 order-first lg:hidden"
              data-v-be2c11a6=""
            >
              <div
                className="w-fit text-subColor-1 font-semibold text-[2rem]"
                data-v-be2c11a6=""
              >
                Decent benefits for every party
              </div>
            </div>
            <div
              className="mt-14 lg:mt-10 lg:ml-10 lg:w-full text-subColor-1 lg:max-w-[280px]"
              data-v-be2c11a6=""
            >
              <h2
                className="text-[#003a60] text-2xl font-semibold"
                data-v-be2c11a6=""
              >
                Institutional business
              </h2>
              <div
                className="mt-5 flex items-center flex-row text-base"
                data-v-be2c11a6=""
              >
                <div className="mr-3" data-v-be2c11a6="">
                  <svg
                    data-v-be2c11a6=""
                    fill="none"
                    height="20"
                    viewBox="0 0 20 20"
                    width="20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_1828_943)">
                      <path
                        d="M18.3334 9.23257V9.99924C18.3323 11.7963 17.7504 13.5448 16.6745 14.9841C15.5985 16.4234 14.0861 17.4763 12.3628 17.9858C10.6395 18.4953 8.79774 18.4342 7.11208 17.8114C5.42642 17.1886 3.98723 16.0377 3.00915 14.5301C2.03108 13.0226 1.56651 11.2393 1.68475 9.44616C1.80299 7.65304 2.49769 5.94617 3.66525 4.58013C4.83281 3.21409 6.41068 2.26205 8.16351 1.86602C9.91635 1.46999 11.7502 1.65118 13.3917 2.38257"
                        stroke="#2568E4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.66667"
                      />
                      <path
                        d="M18.3333 3.33398L10 11.6757L7.5 9.17565"
                        stroke="#2568E4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.66667"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1828_943">
                        <rect fill="white" height="20" width="20" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>{" "}
                Transact unlimited amounts
              </div>
              <div
                className="mt-3 flex items-center flex-row text-base"
                data-v-be2c11a6=""
              >
                <div className="mr-3" data-v-be2c11a6="">
                  <svg
                    data-v-be2c11a6=""
                    fill="none"
                    height="20"
                    viewBox="0 0 20 20"
                    width="20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_1828_943)">
                      <path
                        d="M18.3334 9.23257V9.99924C18.3323 11.7963 17.7504 13.5448 16.6745 14.9841C15.5985 16.4234 14.0861 17.4763 12.3628 17.9858C10.6395 18.4953 8.79774 18.4342 7.11208 17.8114C5.42642 17.1886 3.98723 16.0377 3.00915 14.5301C2.03108 13.0226 1.56651 11.2393 1.68475 9.44616C1.80299 7.65304 2.49769 5.94617 3.66525 4.58013C4.83281 3.21409 6.41068 2.26205 8.16351 1.86602C9.91635 1.46999 11.7502 1.65118 13.3917 2.38257"
                        stroke="#2568E4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.66667"
                      />
                      <path
                        d="M18.3333 3.33398L10 11.6757L7.5 9.17565"
                        stroke="#2568E4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.66667"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1828_943">
                        <rect fill="white" height="20" width="20" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>{" "}
                zero conflict of interests
              </div>
              <div
                className="mt-3 flex items-start flex-row justify-start text-base"
                data-v-be2c11a6=""
              >
                <div className="mr-3" data-v-be2c11a6="">
                  <svg
                    data-v-be2c11a6=""
                    fill="none"
                    height="20"
                    viewBox="0 0 20 20"
                    width="20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_1828_943)">
                      <path
                        d="M18.3334 9.23257V9.99924C18.3323 11.7963 17.7504 13.5448 16.6745 14.9841C15.5985 16.4234 14.0861 17.4763 12.3628 17.9858C10.6395 18.4953 8.79774 18.4342 7.11208 17.8114C5.42642 17.1886 3.98723 16.0377 3.00915 14.5301C2.03108 13.0226 1.56651 11.2393 1.68475 9.44616C1.80299 7.65304 2.49769 5.94617 3.66525 4.58013C4.83281 3.21409 6.41068 2.26205 8.16351 1.86602C9.91635 1.46999 11.7502 1.65118 13.3917 2.38257"
                        stroke="#2568E4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.66667"
                      />
                      <path
                        d="M18.3333 3.33398L10 11.6757L7.5 9.17565"
                        stroke="#2568E4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.66667"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1828_943">
                        <rect fill="white" height="20" width="20" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>{" "}
                API and white label solutions, customization for your business
                use cases
              </div>
              <div
                className="mt-3 flex items-start text-base"
                data-v-be2c11a6=""
              >
                <div className="mr-3" data-v-be2c11a6="">
                  <svg
                    data-v-be2c11a6=""
                    fill="none"
                    height="20"
                    viewBox="0 0 20 20"
                    width="20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_1828_943)">
                      <path
                        d="M18.3334 9.23257V9.99924C18.3323 11.7963 17.7504 13.5448 16.6745 14.9841C15.5985 16.4234 14.0861 17.4763 12.3628 17.9858C10.6395 18.4953 8.79774 18.4342 7.11208 17.8114C5.42642 17.1886 3.98723 16.0377 3.00915 14.5301C2.03108 13.0226 1.56651 11.2393 1.68475 9.44616C1.80299 7.65304 2.49769 5.94617 3.66525 4.58013C4.83281 3.21409 6.41068 2.26205 8.16351 1.86602C9.91635 1.46999 11.7502 1.65118 13.3917 2.38257"
                        stroke="#2568E4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.66667"
                      />
                      <path
                        d="M18.3333 3.33398L10 11.6757L7.5 9.17565"
                        stroke="#2568E4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.66667"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1828_943">
                        <rect fill="white" height="20" width="20" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>{" "}
                Internet speed & 24/7/365 accessibility
              </div>
              <div
                className="flex items-center flex-col justify-center z-50 fixed inset-0 overflow-hidden"
                data-v-be2c11a6=""
                style={{
                  display: "none",
                }}
                title="Make a request"
              >
                <div className="absolute inset-0 bg-[#003A604D] overflow-hidden from-gray-700 via-gray-900 to-gray-700" />
                <div
                  className="max-h-modal w-11/12 md:w-3/5 lg:w-2/5 xl:w-4/12 z-50 bg-white rounded-[6px] text-subColor"
                  style={{
                    display: "none",
                  }}
                >
                  <svg
                    className="ml-auto mt-4 mr-4 cursor-pointer"
                    fill="none"
                    height="25"
                    viewBox="0 0 24 25"
                    width="24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18 6.5L6 18.5"
                      stroke="#97A6C1"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                    <path
                      d="M6 6.5L18 18.5"
                      stroke="#97A6C1"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                  </svg>
                  <div className="pb-8 px-8" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="md:pb-[56px] pb-[64px] bg-grayBackground lg:pt-[186.5px]"
        data-v-be2c11a6=""
      >
        <div
          className=" b-container-big flex flex-col lg:flex-row items-center justify-between"
          data-v-be2c11a6=""
        >
          <div
            className="b-container-column mb-10  flex justify-center items-start  md:max-w-full lg:w-2/4"
            data-v-be2c11a6=""
          >
            <h2
              className="lydiaSubtitleContainer flex flex-col w-fit"
              data-v-be2c11a6=""
            >
              What defines Lydia
            </h2>
          </div>
          <div
            className="lg:mt-0 mt-0 md:w-2/4 md:flex md:justify-between md:m-0 m-auto"
            data-v-be2c11a6=""
          >
            <div
              className="b-container-column  md:max-w-[485px] lg:max-w-max justify-center m-auto"
              data-v-be2c11a6=""
            >
              <div
                className="flex flex-col justify-center items-center lg:flex-row"
                data-v-be2c11a6=""
              >
                <div
                  className="mb-[60px] lg:mb-0 lg:bg-white py-5 flex flex-col md:w-1/2 items-center lg:rounded-[1.6rem]  "
                  data-v-be2c11a6=""
                >
                  <div
                    className="  lg:max-h-[32rem] flex justify-center items-center"
                    data-v-be2c11a6=""
                  >
                    <img
                      alt="CBDC"
                      data-v-be2c11a6=""
                      src={AboutDefine1}
                      className="w-full"
                    />
                  </div>
                  <div
                    className="lg:px-5 text-subColor mt-10"
                    data-v-be2c11a6=""
                  >
                    <h3
                      className="text-[#003a60] text-2xl font-semibold"
                      data-v-be2c11a6=""
                    >
                      Worldwide Reach
                    </h3>
                    <div className=" mt-3 lg:mt-2 text-base" data-v-be2c11a6="">
                      Ensure your assets are protected with our inheritance
                      feature...
                    </div>
                  </div>
                </div>
                <div
                  className="lg:bg-white py-5 lg:rounded-2xl lg:ml-4 mb-[60px] lg:mb-0 w-full md:w-1/2"
                  data-v-be2c11a6=""
                >
                  <div
                    className="lg:max-h-[32rem]  flex items-center justify-center max-h-[32rem]"
                    data-v-be2c11a6=""
                  >
                    <img
                      alt="Legal euro stablecoin"
                      data-v-be2c11a6=""
                      src={AboutDefine2}
                    />
                  </div>
                  <div
                    className="lg:px-5 text-subColor mt-10"
                    data-v-be2c11a6=""
                  >
                    <h3
                      className="text-[#003a60] text-2xl font-semibold"
                      data-v-be2c11a6=""
                    >
                      Proven Legacy
                    </h3>
                    <div className=" mt-3 lg:mt-2 text-base" data-v-be2c11a6="">
                      Protect your wealth from inflation by storing it in Lydia
                      Stable Coin
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="flex flex-col justify-center items-center lg:flex-row lg:mt-8"
                data-v-be2c11a6=""
              >
                <div
                  className="gradientBorder lg:rounded-[18px] mb-[60px] lg:mb-0  md:w-1/2 w-full"
                  data-v-be2c11a6=""
                >
                  <div
                    className="ml-[2px] mr-[2px] py-5 lg:bg-white rounded-b-[1.6rem] rounded-t-[1.6rem] flex flex-col "
                    data-v-be2c11a6=""
                  >
                    <div
                      className="lg:max-h-[32rem] flex justify-center items-center"
                      data-v-be2c11a6=""
                    >
                      <img
                        alt="Central bank crypto"
                        data-v-be2c11a6=""
                        src={AboutDefine3}
                      />
                    </div>
                    <div
                      className="lg:px-5 mt-10 text-subColor"
                      data-v-be2c11a6=""
                    >
                      <h3
                        className="text-[#003a60] text-2xl font-semibold"
                        data-v-be2c11a6=""
                      >
                        Unrivalled safety of funds
                      </h3>
                      <div
                        className="mt-3 lg:mt-2 text-base"
                        data-v-be2c11a6=""
                      >
                        Use Lydia Wallet to purchase Lydia Coins
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="lg:bg-white py-5 flex flex-col items-center lg:flex-grow lg:max-w-[592px] rounded-[1.6rem] lg:ml-4 w-full md:w-1/2 "
                  data-v-be2c11a6=""
                >
                  <div
                    className=" lg:max-h-[32rem] flex items-center"
                    data-v-be2c11a6=""
                  >
                    <img
                      alt="Lyd stabelcoin"
                      data-v-be2c11a6=""
                      src={AboutDefine4}
                    />
                  </div>
                  <div
                    className="lg:px-5 mt-10 text-subColor w-full md:max-w-max"
                    data-v-be2c11a6=""
                  >
                    <h3
                      className="text-[#003a60] text-2xl font-semibold"
                      data-v-be2c11a6=""
                    >
                      No exposure to USD risks
                    </h3>
                    <div className=" mt-3 lg:mt-2 text-base" data-v-be2c11a6="">
                      Use Lydia Wallet to purchase Lydia Stable Coins
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <JoinLydia />
    </>
  );
};
export default About;
