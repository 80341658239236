import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { FormikHelpers } from "formik";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import axios from "axios";
import HelmetComponent from "../../component/Reusable/HelmetComponent";

interface ContactFormValues {
  firstName: string;
  lastName: string;
  workEmail: string;
  jobTitle: string;
  companyName: string;
  typeOfCompany: string;
  numberOfEmployees: number;
  businessGoals: string;
  howLydiaHelp: string;
}

const ContactUs: React.FC = () => {
  const initialValues: ContactFormValues = {
    firstName: "",
    lastName: "",
    workEmail: "",
    jobTitle: "",
    companyName: "",
    typeOfCompany: "",
    numberOfEmployees: 0,
    businessGoals: "",
    howLydiaHelp: "",
  };

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .matches(/^(?!\s)[A-Za-z ]+(?<!\s)$/, "Please enter valid First name")
      .required("First name is required"),
    lastName: Yup.string()
      .matches(/^(?!\s)[A-Za-z ]+(?<!\s)$/, "Please enter valid Last name")
      .required("Last name is required"),
    workEmail: Yup.string()
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Invalid email"
      )
      .required("Work email is required"),
    jobTitle: Yup.string()
      .matches(/^(?!\s)[A-Za-z ]+(?<!\s)$/, "Please enter valid Job Title")
      .required("Jobtitle is required"),
    companyName: Yup.string()
      .matches(/^(?!\s)[A-Za-z ]+(?<!\s)$/, "Please enter valid Company Name")
      .required("Company name is required"),
    typeOfCompany: Yup.string()
      .matches(/^(?!\s)[A-Za-z ]+(?<!\s)$/, "Please enter valid Type")
      .required("Type of Company is required"),
    numberOfEmployees: Yup.number()
      .required("Number of Employees is required")
      .positive("Number of employees must be a positive number")
      .integer("Number of employees must be an integer"),
    businessGoals: Yup.string().required("This field is required"),

    howLydiaHelp: Yup.string().required("This field is required"),
  });

  const onSubmit = async (
    values: ContactFormValues,
    { setSubmitting, resetForm }: FormikHelpers<ContactFormValues>
  ) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/marketing/`,
        {
          type: "contact-us",
          fname: values.firstName.trim(),
          lname: values.lastName.trim(),
          email: values.workEmail,
          job: values.jobTitle.trim(),
          company: values.companyName.trim(),
          companyType: values.typeOfCompany.trim(),
          employeeCount: values.numberOfEmployees.toString(),
          interest: values.businessGoals.replace(/\r/g, ""),
          description: values.howLydiaHelp.trim(),
        }
      );

      toast.success(response.data.msg);

      resetForm();
    } catch (error: unknown) {
      if (axios.isAxiosError(error) && error?.response?.data?.msg) {
        toast.error(error?.response?.data?.msg);
      } else if (error instanceof Error) {
        toast.error(error.message);
      } else {
        toast.error("An unknown error occurred.");
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <HelmetComponent
        title="Lydia Coin - Contact Us"
        description="Get in touch with Lydia Coin for any inquiries or support."
        keywords="Lydia Coin, Contact, Support, Inquiries"
        ogTitle="Lydia Coin - Contact Us"
        ogDescription="Get in touch with Lydia Coin for any inquiries or support."
        ogImage="https://lydia-coin-landing-page.vercel.app"
        ogUrl="https://lydia-coin-landing-page.vercel.app"
        twitterTitle="Lydia Coin - Contact Us"
        twitterDescription="Get in touch with Lydia Coin for any inquiries or support."
        twitterImage="https://lydia-coin-landing-page.vercel.app"
      />

      <div className="b-container-big pt-28 pb-32">
        <div className="flex flex-col items-center justify-center md:px-4 flex-1 pb-4 min-w-64">
          <div className="lydiaTitleContainer text-center mb-6">Contact Us</div>
          <p className="text-subColor md:w-2/3 text-center">
            Find out why the world’s leading financial services companies choose
            Lydia to power their blockchain and digital asset solutions.
          </p>
        </div>
        <div className="flex mt-16 justify-center items-start flex-col md:flex-row">
          <div className="flex-1 !pt-0 rounded-md w-full max-w-2xl">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ isSubmitting }) => (
                <Form className="space-y-4">
                  <div className="grid md:grid-cols-2 gap-4">
                    <div>
                      <label
                        htmlFor="firstName"
                        className="block text-gray-700"
                      >
                        First Name
                        <span className="warning-color">*</span>
                      </label>
                      <Field
                        name="firstName"
                        type="text"
                        className="w-full px-4 py-1 bg-slate-200 mt-1 border border-gray-400 rounded-lg"
                      />
                      <ErrorMessage
                        name="firstName"
                        component="div"
                        className="text-red-500 text-sm mt-1"
                      />
                    </div>
                    <div>
                      <label htmlFor="lastName" className="block text-gray-700">
                        Last Name
                        <span className="warning-color">*</span>
                      </label>
                      <Field
                        name="lastName"
                        type="text"
                        className="w-full px-4 py-1 bg-slate-200 mt-1 border border-gray-400 rounded-lg"
                      />
                      <ErrorMessage
                        name="lastName"
                        component="div"
                        className="text-red-500 text-sm mt-1"
                      />
                    </div>
                  </div>

                  <div>
                    <label htmlFor="workEmail" className="block text-gray-700">
                      Work Email
                      <span className="warning-color">*</span>
                    </label>
                    <Field
                      name="workEmail"
                      type="email"
                      className="w-full px-4 py-1 bg-slate-200 mt-1 border border-gray-400 rounded-lg"
                    />
                    <ErrorMessage
                      name="workEmail"
                      component="div"
                      className="text-red-500 text-sm mt-1"
                    />
                  </div>

                  <div>
                    <label htmlFor="jobTitle" className="block text-gray-700">
                      Job Title
                      <span className="warning-color">*</span>
                    </label>
                    <Field
                      name="jobTitle"
                      type="text"
                      className="w-full px-4 py-1 bg-slate-200 mt-1 border border-gray-400 rounded-lg"
                    />
                    <ErrorMessage
                      name="jobTitle"
                      component="div"
                      className="text-red-500 text-sm mt-1"
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="companyName"
                      className="block text-gray-700"
                    >
                      Company Name
                      <span className="warning-color">*</span>
                    </label>
                    <Field
                      name="companyName"
                      type="text"
                      className="w-full px-4 py-1 bg-slate-200 mt-1 border border-gray-400 rounded-lg"
                    />
                    <ErrorMessage
                      name="companyName"
                      component="div"
                      className="text-red-500 text-sm mt-1"
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="typeOfCompany"
                      className="block text-gray-700"
                    >
                      Type of Company
                      <span className="warning-color">*</span>
                    </label>
                    <Field
                      name="typeOfCompany"
                      type="text"
                      className="w-full px-4 py-1 bg-slate-200 mt-1 border border-gray-400 rounded-lg"
                    />
                    <ErrorMessage
                      name="typeOfCompany"
                      component="div"
                      className="text-red-500 text-sm mt-1"
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="numberOfEmployees"
                      className="block text-gray-700"
                    >
                      Number of Employees
                      <span className="warning-color">*</span>
                    </label>
                    <Field
                      name="numberOfEmployees"
                      type="number"
                      className="w-full px-4 py-1 bg-slate-200 mt-1 border border-gray-400 rounded-lg"
                    />
                    <ErrorMessage
                      name="numberOfEmployees"
                      component="div"
                      className="text-red-500 text-sm mt-1"
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="businessGoals"
                      className="block text-gray-700"
                    >
                      I want to learn how my business can work with Lydia to:
                      <span className="warning-color">*</span>
                    </label>
                    <Field
                      name="businessGoals"
                      as="select"
                      className="w-full px-4 py-1 bg-slate-200 mt-1 border border-gray-400 rounded-lg"
                    >
                      <option value="">Please Select</option>
                      <option
                        value="Enable my customers to buy, sell, hold, or transfer
                       digital assets"
                      >
                        Enable my customers to buy, sell, hold, or transfer
                        digital assets
                      </option>
                      <option value="Launch a branded stablecoin">
                        Launch a branded stablecoin
                      </option>
                      <option value="Build payment or remittance solutions">
                        Build payment or remittance solutions
                      </option>
                    </Field>
                    <ErrorMessage
                      name="businessGoals"
                      component="div"
                      className="text-red-500 text-sm mt-1"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="howLydiaHelp"
                      className="block text-gray-700"
                    >
                      Why Do You Want To Partner With Lydia
                      <span className="warning-color">*</span>
                    </label>
                    <Field
                      name="howLydiaHelp"
                      as="textarea"
                      rows={4}
                      className="w-full px-4 py-1 bg-slate-200 mt-1 border border-gray-400 rounded-lg"
                    />
                    <ErrorMessage
                      name="howLydiaHelp"
                      component="div"
                      className="text-red-500 text-sm mt-1"
                    />
                  </div>

                  <div className="text-left">
                    <button
                      type="submit"
                      className="btnPrimary md:m-0 m-auto w-max btnDefault max-h-[48px] flex flex-row items-center justify-center md:mb-0 md:mr-2"
                      disabled={isSubmitting}
                    >
                      Submit
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};
export default ContactUs;
